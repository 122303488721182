import * as React from "react"
import {Link} from "gatsby"
import Layout from "../components/Layout/Layout";
import Nadpis from "../components/Layout/Nadpis";

const oNas = () => {
    return (
        <Layout>
            <Nadpis>
                <h1>O nás</h1>
                <h2>Naše vize</h2>
            </Nadpis>
            <div className="container">
                <p>
                    Zakládáme si na individuálním a osobním přístupu ke každému projektu,
                    který spravujeme. Chceme být partnerem vašeho projektu od začátku
                    až do konce.
                </p>
            </div>
        </Layout>
    )
}

export default oNas
